<template>
  <b-row>
    <b-col cols="12" v-if="can('show/pengajuan-npp')">
      <b-card title="Preview Data Pengajuan NPP">
        <b-table
          striped
          hover
          responsive
          show-empty
          class="position-relative"
          :items="getItems"
          :fields="fields"
          ref="tableFe"
        >
          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          <template #emptyfiltered>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(name)="data">
            {{ data.item.employee }} / {{ data.item.name }}
          </template>

          <template #cell(daily_money)="data">
            {{ data.item.daily_money | currency }}
          </template>

          <template #cell(total_accomodation)="data">
            {{ data.item.total_accomodation | currency }}
          </template>

          <template #cell(total_transportation)="data">
            {{ data.item.total_transportation | currency }}
          </template>

          <template #cell(folder_url)="data">
            <span>
              <b-link
                class="btn btn-info"
                target="_blank"
                :href="data.item.folder_url"
              >
                Cek Kwitansi
              </b-link>
            </span>
          </template>
        </b-table>
        <b-card-footer>
          <!-- submit and reset -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1 float-right"
            :disabled="isLoading"
            @click="submitGenerated"
          >
            <div v-if="isLoading">
              Simpan <feather-icon icon="LoaderIcon" />
            </div>
            <div v-else>Simpan</div>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="outline-secondary"
            :to="{ name: 'pengajuan-npp' }"
          >
            Batal
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-card>
        <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">Tidak Memiliki Akses</h2>
            <p class="mb-2">Anda tidak memiliki akses pada halaman ini</p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: 'red !important';
}
</style>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BButton,
  BButtonGroup,
  BInputGroup,
  BFormRadioGroup,
  BForm,
  BFormInput,
  BFormTextarea,
  BInputGroupAppend,
  BCardBody,
  BFormGroup,
  BFormSelect,
  BPagination,
  VBPopover,
  BFormCheckbox,
  BLink,
  BCardFooter,
} from 'bootstrap-vue'
import TableFe from '@/views/table/bs-table/TableFE'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'SppdList',
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    TableFe,
    BButton,
    BButtonGroup,
    BInputGroup,
    BFormRadioGroup,
    BForm,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BPagination,
    VBPopover,
    BFormCheckbox,
    BLink,
    BCardFooter,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      config: {
        api: '/npps',
        redirect: 'pengajuan-npp-generated',
      },
      errors: '',
      model: {
        note: '',
        status: '',
      },
      show1: false,
      show2: false,
      isLoading: false,
      fields: [
        'no',
        { key: 'sppd_number', label: 'No SPPD', thStyle: 'min-width:250px' },
        { key: 'name', label: 'NIPPOS / Nama', thStyle: 'min-width:260px' },
        {
          key: 'daily_money',
          label: 'Uang Harian',
          thStyle: 'min-width:180px',
        },
        {
          key: 'total_accomodation',
          label: 'Biaya Akomodasi',
          thStyle: 'min-width:180px',
        },
        {
          key: 'total_transportation',
          label: 'Biaya Transportasi',
          thStyle: 'min-width:180px',
        },
        { key: 'total', label: 'Total Biaya', thStyle: 'min-width:180px' },
        { key: 'folder_url', label: 'Kwitansi', thStyle: 'min-width:200px' },
      ],
      items: [],
      status: [],
      currentSppd: [],
      statusList: [
        { value: null, text: 'Semua' },
        { value: 0, text: 'Menunggu Approval Admin' },
        { value: 1, text: 'Menunggu Approval Manajer Ruta' },
        { value: 2, text: 'Ditolak Admin' },
        { value: 3, text: 'Disetujui' },
        { value: 4, text: 'Ditolak' },
      ],
      statusList1: [
        {
          label: 'Setujui',
          value: 1,
        },
        {
          label: 'Tolak',
          value: 2,
        },
      ],
      statusList2: [
        {
          label: 'Setujui',
          value: 3,
        },
        {
          label: 'Tolak',
          value: 4,
        },
      ],
      list_id: '',
      status_id: null,
    }
  },
  watch: {
    search: function () {
      this.$refs.tableFe.refresh()
    },
  },
  computed: {},
  mounted() {
    let _ = this
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text,
        )
      }
    }
  },
  methods: {
    getItems(ctx, callback) {
      const _ = this

      let endpoint = `${_.config.api}/preview`
      axios
        .get(endpoint)
        .then(resp => {
          const consume = resp.data
          callback(consume.data || [])
          this.currentSppd = consume
        })
        .catch(err => {
          console.log(err)
        })
      return null
    },
    submitGenerated() {
      let _ = this
      _.isLoading = true
      const url = `${this.config.api}`
      axios
        .post(url)
        .then(res => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              event: 'success',
              title: 'Berhasil',
              text: 'Data berhasil di generate',
            },
          })
          _.isLoading = false
        })
        .catch(e => {
          let vm = this
          vm.showDismissibleAlert = true
          if (typeof e.response.data.message === 'object') {
            vm.errors = e.response.data.data
            vm.isLoading = false
          } else {
            vm.messages = e.response.data.message
            vm.errors = e.response.data.data
            vm.isLoading = false
          }
        })
    },
  },
}
</script>

<style>
.b-popover {
  font-size: 10px;
}
</style>
